/* eslint-disable @typescript-eslint/strict-boolean-expressions */
interface Logger {
  error: (...args: unknown[]) => void;
}
interface ExtendedDocument extends Document {
  cancelFullScreen?: Document['exitFullscreen'];
  mozCancelFullScreen?: Document['exitFullscreen'];
  mozFullScreenElement?: Element;
  msExitFullScreen?: Document['exitFullscreen'];
  msFullscreenElement?: Element;
  webkitCancelFullScreen?: Document['exitFullscreen'];
  webkitExitFullscreen?: Document['exitFullscreen'];
  webkitFullscreenElement?: Element;
}
interface ExtendedHTMLElement extends HTMLElement {
  mozRequestFullScreen?: Element['requestFullscreen'];
  msRequestFullscreen?: Element['requestFullscreen'];
  requestFullScreen?: Element['requestFullscreen'];
  webkitRequestFullScreen?: Element['requestFullscreen'];
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): void => {};
const defaultElement = document.documentElement || document.body;

export const isFullscreenSupported = (element?: HTMLElement): boolean => {
  const el: ExtendedHTMLElement = element || defaultElement;

  return !!(el.requestFullscreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullscreen);
};

export const isFullscreenActive = (): boolean => {
  const doc: ExtendedDocument = document;

  const fullscreenElement =
    doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement || null;

  return fullscreenElement != null;
};

export const isWindowOnTheWholeScreen = (): boolean =>
  window.screen.width === window.innerWidth && window.screen.height === window.innerHeight;

export const enterFullscreen = (element?: HTMLElement, logger?: Logger): void => {
  if (!isFullscreenSupported()) {
    if (logger) {
      logger.error('turnFullscreenOn :: fullscreen is not supported');
    } else {
      throw new Error('turnFullscreenOn :: fullscreen is not supported');
    }
  }

  const el: ExtendedHTMLElement = element || defaultElement;
  const requestMethod =
    el.webkitRequestFullScreen || el.mozRequestFullScreen || el.requestFullScreen || el.msRequestFullscreen || noop;
  (requestMethod as (this: ExtendedHTMLElement) => Promise<void>).call(el);
};

export const exitFullscreen = (logger?: Logger): void => {
  if (!isFullscreenSupported()) {
    if (logger) {
      logger.error('turnFullscreenOff :: fullscreen is not supported');
    } else {
      throw new Error('turnFullscreenOff :: fullscreen is not supported');
    }
  }

  const doc: ExtendedDocument = document;
  const cancelFullScreen =
    doc.cancelFullScreen || doc.webkitCancelFullScreen || doc.mozCancelFullScreen || doc.msExitFullScreen || noop;
  cancelFullScreen.call(doc);
};
